import React, { useEffect, useState } from 'react';
import './ReviewsSection.scss'
import { Container } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';

let reviews = [
    {
        name: "Integrity",
        ratings: 5,
        review: "Muhammad is a true professional and excellent at understanding what we wanted and how to get there. He got to the core of how we want to be positioned and helped us get to where we wanted to be.Will absolutely use again...As great of a designer as he is, he is an even better guy...Thank you Muhammad for all of your help!"
    },
    {
        name: "DRMS",
        ratings: 5,
        review: "Muhammad was very professional and answered every question I had. We ended up doing a more extensive job on the website and he was so great to work with. Very timely and experienced. Highly rbe. Willd!"
    },
    {
        name: "My eWeight Loss Journey",
        ratings: 5,
        review: "Moe helped develop my telemedicine website from beginning to end. He showed me a few examples for my website, provided me with a few ideas and let me drive everything from there. I was responsible for providing him with the information for the site and he was responsible for putting it together. He was very patient with me and made himself very available."
    },
    {
        name: "Sequoia Therapy",
        ratings: 5,
        review: "Muhammed and his Team, Did great work with finishing my web-site and was pleased. There was some hiccups with executing some communications but his team was willing and ready to make the changes and work with whatever was not executed correctly. Just know Muhammed is passionate about his work and wants his work to show his passion. Great work done by him and his team."
    },
    {
        name: "All Season Digital",
        ratings: 5,
        review: "Muhammad is an incredible designer and I highly recommend working with him. He took my ideas and created me a beautiful website."
    },
    {
        name: "Invicta Consulting",
        ratings: 5,
        review: "I really enjoyed working with Muhammad. It was very easy to communicate my vision to him, he works fast and completed my project before the deadline. I would highly recommend Muhammad to anyone."
    },
    {
        name: "MYLRES",
        ratings: 5,
        review: "Muhammad was very prompt and professional throughout the entire project. He was always available to speak and meet when we needed to make changes to the project. Also, there was never an issue when I wanted to make changes and then revert back. My team and I had never built a website before and I don't think we would have one currently it it wasn't for Muhammad and his team. They did an excellent job of explaining all angles of the project and really guiding us through. He was a pleasure all around to work with and I would definitely recommend him and his company. We will be a repeat customer!"
    },
    {
        name: "EZ Barber Supply",
        ratings: 5,
        review: "If I could give him a 100 stars rating, I would. Muhammad is friendly, knowledgeable, patient and a WordPress Developer genius. I must admit, I’ve used Upwork services before and did not have a good experience at all. A year later, I’ve decided to give this service one more chance. I ended up hiring Muhammad and I am so thankful that I did. He’s very easy to work with. He built my website ezbarbersupply.com and created two custom logos that really represent what my business is about."
    },
    {
        name: "SIEGEMANNSTOCK",
        ratings: 5,
        review: "Very patient and professional. Muhammad is responsive to communication, and successfully completed all the tasks I required. It just took longer than expected."
    },
    {
        name: "My Primary Cleaning ",
        ratings: 5,
        review: "Muhammad and his team did an amazing work for my website and it’s content. They were able to give me a clear direction on what I needed to do for my website to create more sales and to provide a better experience for my customers. They are very knowledgeable and best of all friendly and professional. I appreciate their hard work!!!"
    },
    {
        name: "Rasalina Williamson",
        ratings: 5,
        review: "If I could give him a 100 stars rating, I would. Muhammad is friendly, knowledgeable, patient and a WordPress Developer genius. I must admit, I’ve used Upwork services before and did not have a good experience at all. A year later, I’ve decided to give this service one more chance. I ended up hiring Muhammad and I am so thankful that I did. He’s very easy to work with. He built my website ezbarbersupply.com and created two custom logos that really represent what my business is about."
    },
    {
        name: "Perfectsmartenup",
        ratings: 5,
        review: "Muhammad did an excellent job on my website development project and I enjoyed working with him. His communication with me was very good and his skill and performance level to meet each step of the process was strong. I will call on Muhammad to help with other projects in the near future."
    },
    {
        name: "GODavis Freight",
        ratings: 5,
        review: "Muhammad was very professional doing the entire process of building our company's website from phase I to deployment. He provided excellent communication by making himself available to answer all questions via emails or by phone. He shared the websites of business' he created to display his amazing talent and skills of website development. He provided recommendations and suggestions after the initial consultation to help align our vision of the website. The only time he wasn't available was on weekends. He is very patient, diligent and persistent in getting the project completed within the timeframe agreed upon. We plan to utilize his services in the future when updates or changes are needed to the website."
    },
    {
        name: "INKY Blackness",
        ratings: 5,
        review: "I've worked with a few developers and Muhammad and his team are light years ahead of the rest. Extremely competent, they were a joy to work with and produced an excellent result Definitely work with them again."
    },
    {
        name: "Graham Global Group, LLC",
        ratings: 5,
        review: "I am completely satisfied with the work that I received. He had very affective communication and a quick turnaround time, well within my needs. He really brought my vision to life and I would 100% work with him again."
    }
]

function ReviewsSection() {

    useEffect(() => {
        let card = document.querySelectorAll(".review-card");
        let contentBox = document.querySelectorAll(".contentBox");

        for (let i = 0; i < card.length; i++) {
            card[i].addEventListener("mouseover", function () {
                for (let i = 0; i < contentBox.length; i++) {
                    contentBox[i].className = "contentBox";
                }
                document.getElementById(this.dataset.id).className = "contentBox active";

                for (let i = 0; i < card.length; i++) {
                    card[i].className = "review-card";
                }
                this.className = "review-card active";
            });
        }

    }, [])

    let itemsPerPage = 3

    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = reviews.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(reviews.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % reviews.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (

        <section className='reviews'>

            <Container>
                <div className='heading text-white'>
                    <h2>What My Clients Says</h2>
                </div>
                <div class="cards">
                    {currentItems.map((a, i) => (
                        <div key={i} class={`review-card ${i === 0 && "active"}`} data-id={`content${i}`}>
                            <div className='reviewName'>
                                <h3>{a.name}</h3>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                            </div>
                            <div class="gradient"></div>
                        </div>
                    ))}
                    {window.screen.width > 650 &&
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=""
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel=""
                            renderOnZeroPageCount={null}
                            className='pagination'
                        />
                    }
                </div>
                <div class="content">
                    {currentItems.map((a, i) => (
                        <div key={i} class={`contentBox ${i === 0 && "active"}`} id={`content${i}`}>
                            <div class="text">
                                <h2>{a.name}</h2>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                    </svg>
                                </span>
                                <p>{a.review}</p>
                            </div>
                        </div>
                    ))}
                </div>
                {window.screen.width < 650 &&
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=""
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageCount}
                        previousLabel=""
                        renderOnZeroPageCount={null}
                        className='pagination'
                    />
                }
            </Container>
        </section>
    );
}

export default ReviewsSection;