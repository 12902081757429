import './cardAnimation.scss'

import LogoDesign from './assets/images/Logo Design.webp'

import logo1 from './assets/images/L1.webp'
import logo2 from './assets/images/L2.webp'
import logo3 from './assets/images/L3.webp'
import logo4 from './assets/images/L4.webp'
import logo5 from './assets/images/L5.webp'
import logo6 from './assets/images/logo6.webp'
import logo7 from './assets/images/logo7.webp'
import logo8 from './assets/images/logo8.webp'
import logo9 from './assets/images/logo9.webp'
import logo10 from './assets/images/logo10.webp'
import logo11 from './assets/images/logo11.webp'
import logo12 from './assets/images/logo12.webp'


export default function CardAnimation(props) {
    return (
        <div className='cardMain'>
            <div className='heading'>
                <h2>Logo Design</h2>
            </div>

            <div className='logo-design'>
                <img src={LogoDesign} alt='logo designing' />
            </div>

            <div className="card first">
                <img src={logo1} alt='logo1' />
            </div>
            <div className="card second">
                <img src={logo2} alt='logo2' />
            </div>
            <div className="card third">
                <img src={logo3} alt='logo3' />
            </div>
            <div className="card fourth">
                <img src={logo4} alt='logo4' />
            </div>
            <div className="card fifth">
                <img src={logo5} alt='logo5' />
            </div>
            <div className="card sixth">
                <img src={logo6} alt='logo6' />
            </div>
            <div className="card seventh">
                <img src={logo7} alt='logo7' />
            </div>
            <div className="card eightth">
                <img src={logo8} alt='logo8' />
            </div>
            <div className="card nineth">
                <img src={logo9} alt='logo9' />
            </div>
            <div className="card tenth">
                <img src={logo10} alt='logo10' />
            </div>
            <div className="card eleventh">
                <img src={logo11} alt='logo11' />
            </div>
            <div className="card twelth">
                <img src={logo12} alt='logo12' />
            </div>

            <div className='btn-div'>
                <a href='https://clients.muhammadprojects.com/new-muhammad/logos/'>
                    <button name='Explore More Logo Designs'>Explore More Logo Designs</button>
                </a>
            </div>

        </div>
    )
}