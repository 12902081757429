import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import logo from './assets/images/muhammd-logo-white.webp'

import './Footer.scss'

function Footer() {
    return (
        <footer>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='logo-div text-center'>
                            <a href='/' aria-label='logo'>
                                <img src={logo} alt='logo' />
                            </a>
                        </div>

                        <div className='linkDiv'>
                            <ul>
                                <li><a href='/'>HOME</a></li>
                                <li><a href='https://clients.muhammadprojects.com/new-muhammad/case-study/'>CASE STUDY</a></li>
                                <li><a href='https://clients.muhammadprojects.com/new-muhammad/web_design/'>WEB DESIGN</a></li>
                                <li><a href='https://clients.muhammadprojects.com/new-muhammad/logos/'>LOGO</a></li>
                                <li><a href='https://clients.muhammadprojects.com/new-muhammad/branding/'>BRANDING</a></li>
                                <li><a href='https://clients.muhammadprojects.com/new-muhammad/ui-ux/'>UI / UX</a></li>
                                <li><a href='https://clients.muhammadprojects.com/new-muhammad/social-media/'>SOCIAL MEDIA</a></li>
                                <li><a href='https://clients.muhammadprojects.com/new-muhammad/graphic-design/'>GRAPHIC DESIGN</a></li>
                         
                            </ul>
                        </div>

                    </Col>
                </Row>
            </Container>
            <div className='footerBottom'>
                <Container>
                    <div className='text-div text-center'>
                        <p>© COPYRIGHT 2023. ALL RIGHTS RESERVED BY MUHAMMAD.</p>
                    </div>
                </Container>
            </div>
        </footer>
    )
}

export default Footer