import React from 'react'
import './WhatWeDone.scss'

function WhatWeDone() {
    return (
        <section class="section_numbers">
            <div class="padding-global">
                <div class="container-large">
                    <div class="padding-section-xlarge">
                        <div class="section_header is-no-bottom text-align-center">
                            <div aria-hidden="true" class="numbers_grid">
                                <div class="numbers_item">
                                    <div class="numbers_text">
                                        <span fs-numbercount-end="200" fs-numbercount-element="number" fs-numbercount-start="150" fs-numbercount-duration="500" class="text-span" role="marquee" aria-roledescription="Number count animation from 150 to 200" aria-description="Number count animation from 150 to 200">
                                            200
                                        </span>
                                        <sup class="numbers_super is-v2">
                                            +</sup>
                                    </div>
                                    <div class="text-size-large">
                                        Projects Finished
                                    </div>
                                </div>
                                <div class="numbers_item">
                                    <div class="numbers_text">
                                        <span fs-numbercount-end="5" fs-numbercount-element="number" fs-numbercount-duration="500" role="marquee" aria-roledescription="Number count animation from 0 to 5" aria-description="Number count animation from 0 to 5">
                                            5
                                        </span>
                                        <sup class="numbers_super">
                                            +
                                        </sup>
                                    </div>
                                    <div class="text-size-large">
                                        Years of Experience
                                    </div>
                                </div>
                                <div class="numbers_item">
                                    <div class="numbers_text">
                                        <span fs-numbercount-end="150" fs-numbercount-element="number" fs-numbercount-start="100" fs-numbercount-duration="500" role="marquee" aria-roledescription="Number count animation from 100 to 150" aria-description="Number count animation from 100 to 150">
                                            150
                                        </span>
                                        <sup class="numbers_super is-v3">
                                            +
                                        </sup>
                                    </div>
                                    <div class="text-size-large">
                                        Clients Worldwide
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="numbers_screen">
                <div class="numbers_bg-grad"></div>
                <div class="numbers_grad"></div>
                <div class="numbers_grad is-right"></div>
                <div class="numbers_screen-left">
                    <div class="numbers_screen-box-left">
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5.jpg 1440w" sizes="(max-width: 991px) 100vw, 34vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18.jpg 1440w" sizes="(max-width: 991px) 100vw, 9vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15.jpg 1440w" sizes="(max-width: 991px) 100vw, 6vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8.jpg 1440w" sizes="(max-width: 991px) 100vw, 4vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing.jpg 1440w" sizes="(max-width: 991px) 100vw, 3vw" alt="websites" class="numbers_image" />
                    </div>
                    <div class="numbers_screen-box-left">
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303d74e6139e80de504_BG%20Shots-5.jpg 1440w" sizes="(max-width: 991px) 100vw, 34vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630344c536ff8f9d1eb7_BG%20Shots-18.jpg 1440w" sizes="(max-width: 991px) 100vw, 9vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63038bde350248308e93_BG%20Shots-15.jpg 1440w" sizes="(max-width: 991px) 100vw, 6vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63037cd2c377c64a2bcb_BG%20Shots-8.jpg 1440w" sizes="(max-width: 991px) 100vw, 4vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/6458a1842835a8764a4d8b21_Open%20Graph%20Image%20Pricing.jpg 1440w" sizes="(max-width: 991px) 100vw, 3vw" alt="websites" class="numbers_image" />
                    </div>
                </div>
                <div class="numbers_screen-right">
                    <div class="numbers_screen-box-right">
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17.jpg 1440w" sizes="(max-width: 991px) 100vw, 3vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3.jpg 1440w" sizes="(max-width: 991px) 100vw, 4vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2.jpg 1440w" sizes="(max-width: 991px) 100vw, 6vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7.jpg 1440w" sizes="(max-width: 991px) 100vw, 9vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6.jpg 1440w" sizes="(max-width: 991px) 100vw, 34vw" alt="websites" class="numbers_image" />
                    </div>
                    <div class="numbers_screen-box-right">
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63028bde353618308e46_BG%20Shots-17.jpg 1440w" sizes="(max-width: 991px) 100vw, 3vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63020aef43b1202b6c16_BG%20Shots-3.jpg 1440w" sizes="(max-width: 991px) 100vw, 4vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e63031f1a3600bf6cc2ee_BG%20Shots-2.jpg 1440w" sizes="(max-width: 991px) 100vw, 6vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e6303c3356e1f5e66ae0d_BG%20Shots-7.jpg 1440w" sizes="(max-width: 991px) 100vw, 9vw" alt="websites" class="numbers_image" />
                        <img src="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6.jpg" loading="lazy" srcset="https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6-p-500.jpg 500w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6-p-800.jpg 800w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6-p-1080.jpg 1080w, https://assets.website-files.com/63de80ab03e95f0ae7de2a37/644e630329bd36bd8d345c16_BG%20Shots-6.jpg 1440w" sizes="(max-width: 991px) 100vw, 34vw" alt="websites" class="numbers_image" />
                    </div>

                    <div class="numbers_screen-embed w-embed">

                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhatWeDone