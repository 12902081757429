import './cardAnimation.scss'

import web1 from './assets/images/web1.webp'
import web2 from './assets/images/web2.webp'
import web3 from './assets/images/web3.webp'
import web4 from './assets/images/web4.webp'
import web5 from './assets/images/web5.webp'
import { useEffect, useRef } from 'react'
import { Container } from 'react-bootstrap'


export default function CardAnimationWeb(props) {

    const ref = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                document.getElementById("heading").innerHTML = "Couger Stats Website Design"
            }
        });
        const observer2 = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                document.getElementById("heading").innerHTML = "Ensemble Website Design"
            } else {
                document.getElementById("heading").innerHTML = "Couger Stats Website Design"
            }
        });
        const observer3 = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                document.getElementById("heading").innerHTML = "Black Rhino Website Design"
            } else {
                document.getElementById("heading").innerHTML = "Ensemble Website Design"
            }
        });
        const observer4 = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                document.getElementById("heading").innerHTML = "Jashara Lashes Website Design"
            }
            else {
                document.getElementById("heading").innerHTML = "Black Rhino Website Design"
            }
        });

        const observer5 = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                document.getElementById("heading").innerHTML = "Redeemo Website Design"
            } else {
                document.getElementById("heading").innerHTML = "Jashara Lashes Website Design"
            }
        });
        observer.observe(ref.current);
        observer2.observe(ref2.current);
        observer3.observe(ref3.current);
        observer4.observe(ref4.current);
        observer5.observe(ref5.current);
        return () => {
            observer.disconnect();
            observer2.disconnect();
            observer3.disconnect();
            observer4.disconnect();
            observer5.disconnect();
        }
    }, [])


    return (
        <div className='cardMain web'>
            <div className='heading web'>
                <Container>
                    <h2 id='heading'>Logo Design</h2>
                </Container>
            </div>

            <div ref={ref} className="card first web">
                <img src={web1} alt='logo1' />
            </div>
            <div ref={ref2} className="card second web">
                <img src={web2} alt='logo2' />
            </div>
            <div ref={ref3} className="card third web">
                <img src={web3} alt='logo3' />
            </div>
            <div ref={ref4} className="card fourth web">
                <img src={web4} alt='logo4' />
            </div>
            <div ref={ref5} className="card fifth web">
                <img src={web5} alt='logo5' />
            </div>

            <div className='btn-div'>
                <a href='https://clients.muhammadprojects.com/new-muhammad/web_design/'>
                    <button name='Explore More Website Designs'>Explore More Website Designs</button>
                </a>
            </div>

        </div>
    )
}