'use client';

import web1 from './assets/images/web1.webp'
import web2 from './assets/images/web2.webp'
import web3 from './assets/images/web3.webp'
import web4 from './assets/images/web4.webp'
import web5 from './assets/images/web5.webp'

import viewmorebtn from './assets/images/viewmorebtn.webp'

import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';

const ScrollAnimation = () => {

  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        document.getElementById("img").style.opacity = 0
        setTimeout(() => {
          document.getElementById("img").style.opacity = 1
          document.getElementById("img").src = web1
          if(window.screen.width < 650){
            document.getElementById("webDesignMain").style.background = `url(${web1})`
          }
        }, 300)
      }
    });
    const observer2 = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        document.getElementById("img").style.opacity = 0
        setTimeout(() => {
          document.getElementById("img").style.opacity = 1
          document.getElementById("img").src = web2
          if(window.screen.width < 650){
            document.getElementById("webDesignMain").style.background = `url(${web2})`
          }
        }, 300)
      }
    });
    const observer3 = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        document.getElementById("img").style.opacity = 0
        setTimeout(() => {
          document.getElementById("img").style.opacity = 1
          document.getElementById("img").src = web3
          if(window.screen.width < 650){
            document.getElementById("webDesignMain").style.background = `url(${web3})`
          }
        }, 300)
      }
    });
    const observer4 = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        document.getElementById("img").style.opacity = 0
        setTimeout(() => {
          document.getElementById("img").style.opacity = 1
          document.getElementById("img").src = web4
          if(window.screen.width < 650){
            document.getElementById("webDesignMain").style.background = `url(${web4})`
          }
        }, 300)
      }
    });

    const observer5 = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        document.getElementById("img").style.opacity = 0
        setTimeout(() => {
          document.getElementById("img").style.opacity = 1
          document.getElementById("img").src = web5
          if(window.screen.width < 650){
            console.log(window.screen.width)
            document.getElementById("webDesignMain").style.background = `url(${web5})`
          }
        }, 300)
      }
    });
    observer.observe(ref.current);
    observer2.observe(ref2.current);
    observer3.observe(ref3.current);
    observer4.observe(ref4.current);
    observer5.observe(ref5.current);
    return () => {
      observer.disconnect();
      observer2.disconnect();
      observer3.disconnect();
      observer4.disconnect();
      observer5.disconnect();
    }
  }, [])

  return (
    <div id='webDesignMain' className='webDesignMain' style={{ zIndex: 9, position: "relative", padding: "0px 0" }}>
      <div className='viewMoreBtn'>
        <a href='https://clients.muhammadprojects.com/new-muhammad/web_design/'>
          <img src={viewmorebtn} alt='view more' />
        </a>
      </div>
      <div className='overLay'></div>
      <Container>
        <div className="section">
          <div className="div2">
            <div className="list">
              <div ref={ref} className="services_item-content">
                <h1>Couger Stats Website Design</h1>
              </div>
              <div ref={ref2} className="services_item-content">
                <h1>Ensemble Website Design</h1>
              </div>
              <div ref={ref3} className="services_item-content">
                <h1>Black Rhino Website Design</h1>
              </div>
              <div ref={ref4} className="services_item-content">
                <h1>Jashara Lashes Website Design</h1>
              </div>
              <div ref={ref5} className="services_item-content">
                <h1>Redeemo Website Design</h1>
              </div>
            </div>
          </div>
          <div className="div1">
            <img id="img" src={web1} alt='web image' />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ScrollAnimation;
