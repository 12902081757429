import React, { useState, useRef, useEffect } from 'react';

import brand1 from './assets/images/brand1.webp'
import brand2 from './assets/images/brand2.webp'
import brand3 from './assets/images/brand3.webp'
import brand4 from './assets/images/brand4.webp'

function useTilt(animationDuration = '150ms') {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const unify = (e) => (e.changedTouches ? e.changedTouches[0] : e);

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleEnterEvent = () => {
      el.style.transition = `transform ${animationDuration} ease-out`;
    };

    const handleMoveEvent = (e) => {
      e.preventDefault();

      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = unify(e).clientX;
      state.mouseY = unify(e).clientY;

      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty('--px', px.toFixed(2));
      el.style.setProperty('--py', py.toFixed(2));
    };

    const handleEndEvent = () => {
      el.style.setProperty('--px', 0.5);
      el.style.setProperty('--py', 0.5);
      el.style.transition = `transform ${animationDuration} ease-in`;
    };

    if (window.screen.width > 650) {
      el.addEventListener('mouseenter', handleEnterEvent);
      el.addEventListener('mousemove', handleMoveEvent);
      el.addEventListener('mouseleave', handleEndEvent);
      el.addEventListener('touchstart', handleEnterEvent, { passive: true });
      el.addEventListener('touchmove', handleMoveEvent, { passive: true });
      el.addEventListener('touchend', handleEndEvent, { passive: true });

      return () => {
        el.removeEventListener('mouseenter', handleEnterEvent);
        el.removeEventListener('mousemove', handleMoveEvent);
        el.removeEventListener('mouseleave', handleEndEvent);
        el.removeEventListener('touchstart', handleEnterEvent, { passive: true });
        el.removeEventListener('touchmove', handleMoveEvent, { passive: true });
        el.removeEventListener('touchend', handleEndEvent, { passive: true });
      };

    } else {
      
    }
  }, [animationDuration]);

  return ref;
}

const Slide = ({ image, title, subtitle, description, offset, isPageBackground }) => {
  const active = offset === 0 ? true : null;
  const ref = useTilt(active);

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        '--offset': offset,
        '--dir': offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      {isPageBackground && (
        <div
          className="slideBackground"
          style={{
            backgroundImage: `url('${image}')`,
          }}
        />
      )}
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div className="slideContentInner">
          {title && (
            <h2 className="slideTitle" dir="auto">
              {title}
            </h2>
          )}
          {subtitle && (
            <h3 className="slideSubtitle" dir="auto">
              {subtitle}
            </h3>
          )}
          {description && (
            <p className="slideDescription" dir="auto">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

const Carousel = ({ slides, isPageBackground }) => {
  const [slideIndex, setSlideIndex] = useState(5);

  const handlePrevSlide = () => {
    setSlideIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setSlideIndex((prev) => (prev + 1) % slides.length);
  };

  return (
    <section className="slidesWrapper">
      <div className="slides">
        <button className="prevSlideBtn" onClick={handleNextSlide} name='previous' aria-label="previous">
          <i className="fas fa-chevron-left" />
        </button>

        {[...slides, ...slides, ...slides].map((slide, i) => {
          let offset = slides.length + (slideIndex - i);

          if (typeof slide === 'string') {
            return (
              <Slide image={slide} offset={offset} isPageBackground={isPageBackground} key={i} />
            );
          } else {
            return (
              <Slide
                image={slide.image}
                // title={slide.title}
                // subtitle={slide.subtitle}
                // description={slide.description}
                offset={offset}
                isPageBackground={isPageBackground}
                key={i}
              />
            );
          }
        })}
        <button className="nextSlideBtn" onClick={handlePrevSlide} name='next' aria-label="next">
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    </section>
  );
};

const slides = [
  {
    id: 1,
    title: 'First',
    subtitle: 'slide',
    description: 'Praesent ac sem eget est.',
    image: brand1,
  },
  {
    id: 2,
    title: 'Second',
    subtitle: 'slide',
    description: 'Praesent ac sem eget est.',
    image: brand2,
  },
  {
    id: 3,
    title: 'Third',
    subtitle: 'slide',
    description: 'Praesent ac sem eget est.',
    image: brand3,
  },
  {
    id: 4,
    title: 'First',
    subtitle: 'slide',
    description: 'Praesent ac sem eget est.',
    image: brand4,
  }
];

const Carousel3D = () => <Carousel slides={slides} />;

export default Carousel3D;
